import React from 'react';
import Layout from '../components/global/layout';
import SEO from '../components/seo';
import PageBanner from '../components/layouts/page-banner';
import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import theme from '../lib/theme';
import CallToAction from '../components/layouts/call-to-action';
import Button from '../components/mui/button';

const ExcludedIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.624"
      height="18.624"
      viewBox="0 0 18.624 18.624"
    >
      <g
        id="Group_1024"
        data-name="Group 1024"
        transform="translate(-826.066 -2109)"
      >
        <rect
          id="Rectangle_616"
          data-name="Rectangle 616"
          width="4.159"
          height="22.179"
          transform="translate(829.006 2127.624) rotate(-135)"
          fill="#9998a5"
        />
        <rect
          id="Rectangle_618"
          data-name="Rectangle 618"
          width="4.159"
          height="22.179"
          transform="translate(826.066 2111.941) rotate(-45)"
          fill="#9998a5"
        />
      </g>
    </svg>
  );
};

const IncludedIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.071"
      height="18.624"
      viewBox="0 0 24.071 18.624"
    >
      <g
        id="Group_1021"
        data-name="Group 1021"
        transform="translate(-875.338 -2110.351)"
      >
        <rect
          id="Rectangle_617"
          data-name="Rectangle 617"
          width="4.159"
          height="11.862"
          transform="translate(886.667 2126.034) rotate(135)"
          fill="#8d81d3"
        />
        <rect
          id="Rectangle_619"
          data-name="Rectangle 619"
          width="4.159"
          height="22.179"
          transform="translate(883.726 2128.975) rotate(-135)"
          fill="#8d81d3"
        />
      </g>
    </svg>
  );
};

const StyledTableHeadCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#161420',
    color: theme.palette.common.white,
    border: 'solid 1px #707070',
    fontFamily: 'Visby CF, sans-serif',
    fontSize: 24,
    fontWeight: 600,
  },
}))(TableCell);

const StyledTableHeadRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#161420',
    color: theme.palette.common.white,
    border: 'solid 1px #707070',
  },
  body: {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableSubHead = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    border: 'solid 1px #707070',
    fontSize: 18,
    fontWeight: 600,
    padding: '0 0 10px 0',
    verticalAlign: 'bottom',
  },
}))(TableCell);

const StyledTableCell = withStyles(() => ({
  root: {
    border: 'solid 1px #707070',
  },
  body: {
    fontSize: 18,
    fontWeight: 600,
  },
}))(TableCell);

const StyledEmptyTableCell = withStyles(() => ({
  root: {
    backgroundColor: '#161420',
    color: theme.palette.common.white,
    border: 'solid 1px #707070',
    fontFamily: 'Visby CF, sans-serif',
    fontSize: 24,
    fontWeight: 600,
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  root: {},
  heading: {
    color: theme.palette.primary.dark,
  },
  mostPopular: {
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  table: {
    minWidth: '100%',
  },
  spacer: {
    [theme.breakpoints.down('md')]: {
      minHeight: 40,
    },
  },
  vertLine: {
    borderLeft: '1px solid #7D74BD',
    height: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Pricing: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO
        title="Our Monthly Web Operations Plans and Pricing"
        description="Our monthly website development pricing structure is built on a clear and predictable pricing model and monthly fees."
      />
      <PageBanner
        heading="Keep your costs predictable and your options open"
        imageSrc="pricing-banner.jpg"
      />
      <LightMode>
        <VerticalPadding lg>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={12} md={5}>
                <Typography
                  variant="h2"
                  className={classes.heading}
                  gutterBottom
                >
                  get the plan that works for you
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" component="p">
                  Our monthly pricing plans are built on a clear and predictable
                  pricing model and preset monthly fees. This removes the
                  uncertainty associated with generic, one-size-fits-all web
                  development and maintenance options that are priced based on
                  complex and unpredictable pricing strategies tied to
                  fluctuating monthly consultation and technical support hours.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </LightMode>
      <Box>
        <VerticalPadding lg>
          <Container maxWidth={false}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableHeadCell>features</StyledTableHeadCell>
                    <StyledTableHeadCell align="center">
                      hosting
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="center">
                      small team
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="center">
                      business
                    </StyledTableHeadCell>
                    <StyledTableHeadCell align="center">
                      enterprise
                    </StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Billing
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">Annually</StyledTableCell>
                    <StyledTableCell align="center">Monthly</StyledTableCell>
                    <StyledTableCell align="center">Monthly</StyledTableCell>
                    <StyledTableCell align="center">Monthly</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Monthly Price
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">$125</StyledTableCell>
                    <StyledTableCell align="center">$1,485</StyledTableCell>
                    <StyledTableCell align="center">$2,640</StyledTableCell>
                    <StyledTableCell align="center">$4,400</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Discounted Hourly Rate
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">$160</StyledTableCell>
                    <StyledTableCell align="center">$140</StyledTableCell>
                    <StyledTableCell align="center">$120</StyledTableCell>
                    <StyledTableCell align="center">$100</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Monthly Hours
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">2</StyledTableCell>
                    <StyledTableCell align="center">10</StyledTableCell>
                    <StyledTableCell align="center">20</StyledTableCell>
                    <StyledTableCell align="center">40</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Response Time
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      &lt; 6 hours
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      &lt; 4 hours
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      &lt; 2 hours
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Priority support
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Weekly WordPress Updates<sup>1</sup>
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      24/7 Website Uptime Monitoring
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Detailed Monthly Timesheets
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <ExcludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Proactive Technical SEO Management
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <ExcludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Unused Hours Rollover
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <ExcludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Maximum Rollover Hours Allowed
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <ExcludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">50</StyledTableCell>
                    <StyledTableCell align="center">100</StyledTableCell>
                    <StyledTableCell align="center">200</StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableHeadRow component="th" scope="row">
                      Managed Hosting<sup>2</sup>
                    </StyledTableHeadRow>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IncludedIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box mt={3}>
              <small>
                <sup>1</sup> The time required to update WordPress core/plugins
                will be deducted from your monthly hours if your website is not
                being hosted on Pantheon.
              </small>
            </Box>
            <Box>
              <small>
                <sup>2</sup> Includes one Pantheon Basic plan. Additional
                hosting fees will be applied if you exceed{' '}
                <Link
                  href="https://pantheon.io/pricing-comparison"
                  target="_blank"
                  rel="noopener"
                  style={{ color: '#ccc', textDecoration: 'underline' }}
                >
                  Pantheon’s maximum allowed monthly visits
                </Link>
                , have more than one site, or choose a different plan.
              </small>
            </Box>
          </Container>
        </VerticalPadding>
      </Box>
      <Box bgcolor={theme.palette.primary.main}>
        <VerticalPadding>
          <Container maxWidth={false}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10} md={4}>
                <Typography
                  variant="h3"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{ color: 'white', fontFamily: 'Visby CF' }}
                >
                  spend a little extra,
                  <br />
                  get a little extra
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 500 }}
                >
                  With our pricing structure, you don’t need to change your
                  monthly plan for a short-term initiative or marketing push.
                  You can always add more hours and services, as needed.
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 500 }}
                >
                  Of course, if your monthly requirements change, you can adjust
                  your monthly plan to fit new objectives.
                </Typography>
              </Grid>
              <Grid item xs={12} md={1} className={classes.spacer}></Grid>
              <Grid item md={1} className={classes.vertLine}></Grid>
              <Grid item xs={10} md={4}>
                <Typography
                  variant="h3"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{ color: 'white', fontFamily: 'Visby CF' }}
                >
                  we&apos;re always open
                  <br />
                  for a chat
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 500 }}
                >
                  If you don&apos;t see the right monthly fit for your marketing
                  program, give us a call. We&apos;ll make sure you get the
                  right package to fit your budget.
                </Typography>
                <Box textAlign="center">
                  <Button
                    size="large"
                    variant="outlined"
                    style={{ color: 'white' }}
                    to="/contact/"
                  >
                    give us a call
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </VerticalPadding>
      </Box>
      <CallToAction imageSrc="call-to-action-man-couch.jpg">
        <Typography
          variant="h2"
          color="textSecondary"
          gutterBottom={true}
          style={{ maxWidth: '9em' }}
        >
          Get worry-free web operations
        </Typography>
        <Typography
          variant="body2"
          style={{ fontSize: 24, marginTop: 40, marginBottom: 50 }}
        >
          Develop, launch, manage, and iterate digital marketing assets faster.
          And bring advanced digital experiences to your audience more quickly
          and efficiently.
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="secondary"
          to="/contact/"
        >
          Let&apos;s talk
        </Button>
      </CallToAction>
    </Layout>
  );
};

export default Pricing;
